const {
  USERDETAILS,
  SWITCHACCOUNT,
  STATISTICS,
  CURRENTUSER,
  BUSINESSUSERDATA,
} = require("../constants");
const initialState = {
  userDetail: {},
  switchSuccess: {},
  statistics: {},
  currentuser: {},
  businessData: {},
};

const UserData = (state = initialState, action) => {
  switch (action.type) {
    case USERDETAILS:
      return {
        ...state,
        userDetail: action?.payload,
      };
    case SWITCHACCOUNT:
      return {
        ...state,
        switchSuccess: action?.payload,
      };
    case CURRENTUSER:
      return {
        ...state,
        currentuser: action?.payload,
      };
    case BUSINESSUSERDATA:
      return {
        ...state,
        businessData: action?.payload,
      };

    case STATISTICS:
      return {
        ...state,
        statistics: action.payload,
      };
    default:
      return state;
  }
};
export default UserData;
