import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import UserSettings from "../components/account/settings";
import Login from "../components/auth/login";
import OTP from "../components/auth/otp";
import GenerateQRCode from "../components/common/qrcode";
import CustomerDashboard from "../components/customer/customer-dashboard";
import Dashboard from "../components/dashboard/user-dashboard";
import MerchantDashboard from "../components/merchant/merchant-dashboard";
import PrivateRoute from "./PrivateRoute";

const Routes = () => {
  const LoginData = useSelector(state => state?.LoginData?.loginSuccesData?.accessToken )
  const existingToken = localStorage.getItem('accessToken')
  const [isAuthenticated, setIsAuthenticated ] = useState(existingToken? true : false)

  useEffect(()=>{
    if (existingToken){
      setIsAuthenticated(true)
    }
  },[LoginData ])

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/otpconfirm" component={OTP} />
        <PrivateRoute exact path="/user-dashboard" component={Dashboard} authTokens={isAuthenticated} />
        <PrivateRoute exact path="/user-settings" component={UserSettings} authTokens={isAuthenticated}  />
        <PrivateRoute exact path="/merchant-dashboard" component={MerchantDashboard} authTokens={isAuthenticated}  />
        <PrivateRoute exact path="/business-dashboard" component={CustomerDashboard} authTokens={isAuthenticated}  />
        <PrivateRoute exact path="/qrcode-generate" component={GenerateQRCode} authTokens={isAuthenticated}  />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
