import React, { useEffect, useState } from 'react'
import Header from "./header";
import QRCode from "qrcode.react";
import { useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import { userDetailsActionFn } from '../../redux/actions/userDetailsAction';


 const GenerateQRCode = (props)=> {
    const dispatch = useDispatch();
    const [qrValue, setQRValue] = useState('');
    const [userDetails, setUserDetails] = useState('')
    const [companyName, setCompanyName]=  useState('')


    useEffect(()=>{
        getUserDetails()
    },[])

    const getUserDetails = async() => {
        try {
            const response = await dispatch(userDetailsActionFn())
            const userDataResponse = response?.payload
            setUserDetails(userDataResponse?.data)
            const {serviceProvider, phone,companyName, userId, email,countryCode, isCanReceivePayment,currentAccountType } = userDataResponse?.data?.user;
            let qr = 
            {
                "userId":userId,
                "serviceProvider": serviceProvider,
                "phone":phone,
                "countryCode":countryCode,
                "companyName":companyName,
                "email":email
            }
            setQRValue(JSON.stringify(qr))
            setCompanyName(companyName)

          } catch (err) {
            console.error(err)
          }
    }

    const handleQRDownload =()=> {
        if (userDetails){
            const canvas = document.getElementById("qr-gen");
            const pngUrl = canvas
              .toDataURL("image/png")
              .replace("image/png", "image/octet-stream");
            let downloadLink = document.createElement("a");
            downloadLink.href = pngUrl;
            downloadLink.download = `${companyName}.png`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
    }


    return (
        <>
            <Header/>
            <section className="dash-wrap">
                <div class="settings-block small">
                    {userDetails? 
                    
                        <QRCode
                            id="qr-gen"
                            value={qrValue}
                            size={300}
                            level={"L"}
                            includeMargin={true}
                        />
                    : <></>}
                    <div className="download mt-3 text-center" >
                        <Button className="outline-primary" onClick={handleQRDownload}> Download QR </Button>
                    </div>
                </div>
            </section>
        </>
    )
}

export default GenerateQRCode;