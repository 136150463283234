import React, { useEffect, useState } from "react";
import { Chart } from "chart.js";
import "react-responsive-select/dist/react-responsive-select.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getTopReceiverActionFn,
  getTransactionTypeActionFn,
  paymentVsRequestActionFn,
} from "../../redux/actions/transactionAction";

const PaymentvsRequest = (props) => {
  const dispatch = useDispatch();

  const [totalTransaction, setTotalTransaction] = useState([]);
  const [transactionByTypeData, setTransactionByTypeData] = useState([])
  const [topReceiverData, setTopReceiverData] = useState([])


  useEffect(() => {
    const month = 6
    getPaymentvsRequest(month)
    getTransactionByType(month)
    getTopReceivers()
  }, []);
  

  //======== get payment vs request ============================
  const getPaymentvsRequest = async (month) => {
    try{
      let paymentdata = {
        account_type: "Individual",
        month: month,
      };
      const response  = await dispatch(paymentVsRequestActionFn(paymentdata));
      const paymentVsRequestResponse = response?.payload;
   
      if (paymentVsRequestResponse){
        if(paymentVsRequestResponse?.status == 200){
          if(paymentVsRequestResponse?.data){
            barChart1(paymentVsRequestResponse?.data)
          }
        } else {
          console.error(paymentVsRequestResponse)
        }
      }
    } catch (err){
      console.error(err)
    }
  };

  //======== get transacrion by type ============================
  const getTransactionByType = async (month) => {
    try{
      const response  = await dispatch(getTransactionTypeActionFn(month));
      const transactionByTypeResponse = response?.payload;
   
      if (transactionByTypeResponse){
        if(transactionByTypeResponse?.status == 200){
          if(transactionByTypeResponse?.data){
            barChart2(transactionByTypeResponse?.data)
            setTransactionByTypeData(transactionByTypeResponse?.data)
            var transactionTotal = transactionByTypeResponse?.data?.result?.map((a) => a.count)
            setTotalTransaction(transactionTotal)
          }
        } else {
          console.error(transactionByTypeResponse)
        }
      }
    } catch (err){
      console.error(err)
    }
  }
  
  //========= get top receivers =======================
  const getTopReceivers = async () => {
    try{
      const response  = await dispatch(getTopReceiverActionFn());
      const topReceiverResponse = response?.payload;
   
      if (topReceiverResponse){
        if(topReceiverResponse?.status == 200){
          if(topReceiverResponse?.data){
            setTopReceiverData(topReceiverResponse?.data)
          }
        } else {
          console.error(topReceiverResponse)
        }
      }
    } catch (err){
      console.error(err)
    }
  };


  function transactionPer(value){
      let totalT = totalTransaction?.reduce((a,b)=>a+b,0)
     let calc 
     if (totalT > 0 ){
     calc = (value/totalT) * 100
     return calc.toFixed(2);
     }
     return 0
  }


  const barChart1 = (paymentvsrequest) => {
    var ctx2 = document.getElementById("myChart2").getContext("2d");
    var myChart2 = new Chart(ctx2, {
      type: "bar",
      fillOpacity: 0.8,
      data: {
        labels: paymentvsrequest.graph
          ? paymentvsrequest.graph.map((a) => a.month)
          : [0, 0, 0, 0, 0, 0, 0],
        datasets: [
          {
            label: "Payments",
            backgroundColor: "#38B635",
            borderColor: "#38B635",
            pointBorderColor: "#38B635",
            borderWidth: 2,
            pointRadius: 4,
            pointHoverRadius: 4,
            pointBackgroundColor: "#FFF",
            data: paymentvsrequest.graph
              ? paymentvsrequest.graph.map((a) => a.paymentCount)
              : [0, 0, 0, 0, 0, 0, 0],
          },
          {
            label: "Requests",
            backgroundColor: "#CFEECE",
            borderColor: "#CFEECE",
            pointBorderColor: "#CFEECE",
            borderWidth: 2,
            pointRadius: 4,
            pointHoverRadius: 4,
            pointBackgroundColor: "#FFF",
            data: paymentvsrequest.graph
              ? paymentvsrequest.graph.map((a) => a.requestCount)
              : [0, 0, 0, 0, 0, 0, 0],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        bezierCurve: false,
        elements: {
          line: {
            tension: 0,
          },
        },
        scales: {
          xAxes: [
            {
              gridLines: { color: "rgba(0, 0, 0, 0)" },
            },
          ],
          yAxes: [
            {
              ticks: { beginAtZero: true },
              gridLines: { color: "rgba(244, 244, 244, 1)" },
            },
          ],
        },

        tooltips: {
          custom: function (tooltip) {
            if (!tooltip) return;
            // disable displaying the color box;
            tooltip.displayColors = false;
          },
          callbacks: {
            // use label callback to return the desired label
            label: function (tooltipItem, data) {
              return "GH₵" + tooltipItem.yLabel;
            },
            // remove title
            title: function (tooltipItem, data) {
              return;
            },
          },
          backgroundColor: "#FFF",
          borderColor: "rgba(0, 0, 0, 0.09)",
          borderWidth: 1,
          bodyFontColor: "rgba(0, 0, 0, 1)",
          bodyAlign: "center",
          bodyFontSize: 14,
          bodyFontStyle: 500,
        },
        legend: {
          align: "end",
          labels: {
            boxWidth: 12,
            fontColor: "#A4A7B0",
          },
        },
      },
    });
  };
  const colors = ['#1C932F', '#4EA5F6', '#F75009', '#59E827', '#F79809','#7C27E8'];



  const barChart2 = (transactionbytype) => {
    var ctx3 = document.getElementById("myDoughnutChart").getContext("2d");
    var myDoughnutChart = new Chart(ctx3, {
      type: "doughnut",
      data: {
        labels: transactionbytype.result
          ? transactionbytype.result.map((a) => a.type)
          : ["Shopping", "Payment for Bills", "Food", "School Fees", "Travel"],
        datasets: [
          {
            data: transactionbytype.result
              ? transactionbytype.result.map((a) => a.count)
              : [0, 0, 0, 0, 0],
            backgroundColor: [
              "#1C932F",
              "#4EA5F6",
              "#F75009",
              "#59E827",
              "#F79809",
              "#7C27E8",
            ],
            borderColor: [
              "#1C932F",
              "#4EA5F6",
              "#F75009",
              "#59E827",
              "#F79809",
              "#7C27E8",
            ],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 70,
        tooltips: {
          custom: function (tooltip) {
            if (!tooltip) return;
            // disable displaying the color box;
            tooltip.displayColors = false;
          },
          backgroundColor: "#FFF",
          borderColor: "rgba(0, 0, 0, 0.09)",
          borderWidth: 1,
          bodyFontColor: "rgba(0, 0, 0, 1)",
          bodyAlign: "center",
          bodyFontSize: 14,
          bodyFontStyle: 500,
        },
        legend: {
          align: "center",
          position: "right",

          display: false,
          labels: {
            boxWidth: 42,
            padding: 15,
            fontColor: "#373737",
            
          },
        },
      },
    });
  };

  const alignmentClassName = () => {
    let className={};
    const countData = totalTransaction?.reduce((a,b)=>a+b,0)
    if(!countData){
      className = {left:'50%',top:'50%'}
    }
    return className
  }

  return (
    <div className="sec-block alt">
      <div className="row modified">
        <div className="col-xl-4 col-lg-12">
          <div className="block-single alt">
            <div className="block-heading">
              <h2>Payments vs Requests</h2>
              <div className="custom-select-wrap select-month">
                <select
                  className="custom-select"
                  name="carType1"
                  onChange={(e) => getPaymentvsRequest(e.target.value)}
                >
                  <option value="6">6 Months</option>
                  <option value="12">12 Months</option>
                  <option value="18">18 Months</option>
                  <option value="24">24 Months</option>
                </select>
                {/* <Select
                  className="custom-select"
                  name="carType1"
                  modalCloseButton={<ModalCloseButton />}
                  options={[
                    { value: "6", text: "6 Months" },
                    { value: "12", text: "12 Months" },
                    { value: "18", text: "18 Months" },
                    { value: "24", text: "24 Months" },
                  ]}
                  caretIcon={<CaretIcon />}
                  selectedValue="6"
                  onChange={(newValue) => getPaymentvsRequest(newValue.value)}
                /> */}
              </div>
            </div>
            <div className="inde-business-chart">
              {/* <div className="growth-chart for-user"> */}
              <canvas id="myChart2" />
              {/* </div> */}
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-6">
          <div className="block-single">
            <div className="block-heading">
              <h2>Top Receivers</h2>
            </div>
            <div className="recent-trans">
              <ul className="list-item">
                {topReceiverData ?
                  topReceiverData?.result?.map((receiver, i) => {
                    return (
                      <li key={i}>
                        <span className="list-item-img">
                          <img src={receiver.receiverAvtar} alt="" />
                        </span>
                        <span className="list-item-txt">
                          <h3>{receiver.receiverName}</h3>
                          <h4>
                            <span>{}</span>
                          </h4>
                        </span>
                        <span className="list-item-details">
                          <h5>
                            {receiver.countryCode} {receiver.receiverPhone}
                          </h5>
                        </span>
                      </li>
                    );
                  }) : null }
              </ul>
            </div>
          </div>
        </div>
        <div className="col-xl-4">
          <div className="block-single alt">
            <div className="block-heading">
              <h2>Transactions by Type</h2>
              <div className="custom-select-wrap select-month">
                <select
                  className="custom-select"
                  name="carType1"
                  onChange={(e) => getTransactionByType(e.target.value)}
                >
                  <option value="6">6 Months</option>
                  <option value="12">12 Months</option>
                  <option value="18">18 Months</option>
                  <option value="24">24 Months</option>
                </select>
                {/* <Select
                  name="carType1"
                  modalCloseButton={<ModalCloseButton />}
                  options={[
                    { value: "6-Months", text: "6 Months" },
                    { value: "12-Months", text: "12 Months" },
                    { value: "18-Months", text: "18 Months" },
                    { value: "24-Months", text: "24 Months" },
                  ]}
                  caretIcon={<CaretIcon />}
                  selectedValue="6-Months"
                  onChange={(newValue) => console.log("onChange", newValue)}
                /> */}
              </div>
            </div>
            <div className="user-chart forTrans">
              <canvas id="myDoughnutChart" />

              <div className={'user-chart-count'} style={alignmentClassName()}>
            <h3>{totalTransaction?.reduce((a,b)=>a+b,0)}</h3>
                <p>overall users</p>
              </div>
              <div className="user-chart-level">
                <ul>
                    {transactionByTypeData? transactionByTypeData?.result?.map((item,i)=>{
                        return(
                            <li key={i} >
                            <span className="chart-level-bg level-shop" style={{ backgroundColor: colors[i] }}>{transactionPer(item.count)}%</span>{" "}
                            <span className="chart-level-txt" style={{maxWidth:"100px"}}>{item.type} </span>
                          </li>
                        )
                    }) : null}
                 
                
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentvsRequest;
