import { AuthApi, generateTokenApi, GetCallingCode, GetOperatorList, LoginApi } from "../api/ApiService";
import {
    CALLINGCODE,
  GENERATEOTP,
  GENERATE_TOKEN,
  GETPROVIDER,
  LOGIN,
  LOGIN_ERROR,
  LOGOUT,
  OTPERROR,
} from "../constants";
import { loadingAction } from "./loadingAction";
import { currentUserActionFn } from "./userDetailsAction";

export const GetProviderActionFn = () => {
  return (dispatch) => {
    dispatch(loadingAction(true))
    return GetOperatorList()
      .then((response) => {
        dispatch(loadingAction(false))
        if (response && response.status && response.status === 200) {
          return dispatch({ type: GETPROVIDER, payload: response });
        }
      })
      .catch(({ ...error }) => {
        dispatch(loadingAction(false))
        throw error;
      });
  };
};

export const GetCallingCodeActionFn = () => {
    return (dispatch) => {
      return GetCallingCode()
        .then((response) => {
          if (response && response.status && response.status === 200) {
            return dispatch({ type: CALLINGCODE, payload: response });
          }
        })
        .catch(({ ...error }) => {
          throw error;
        });
    };
  };

export const GenerateOtpActionFn = (data) => {
  return (dispatch) => {
    dispatch(loadingAction(true))

    AuthApi(data)
      .then((response) => {
        dispatch(loadingAction(false))
        if (response && response.status && response.status === 200) {
          return dispatch({ type: GENERATEOTP, payload: response });
        }
      })
      .catch(({ ...error }) => {
        dispatch(loadingAction(false))
         dispatch({ type: OTPERROR, payload: error.response.data });
        throw error;
      });
  };
};

export const SendOtpActionFn = (data) => {
  return (dispatch) => {
    dispatch(loadingAction(true))

    return LoginApi(data)
      .then((response) => {

        dispatch(loadingAction(false))
        // if (response && response.status && response.status === 200) {
          dispatch(currentUserActionFn(response.data.data.accountType))
          return dispatch({ type: LOGIN, payload: response });
        // }
      })
      .catch(({ ...error }) => {
        dispatch(loadingAction(false))
        dispatch({ type: LOGIN_ERROR, payload: error.response.data });

        throw error;
      });
  };
};

export const GenerateTokenActionFn = () =>{
  return new Promise((resolve, reject) => {
    return async (dispatch) => {
    
      const response = await generateTokenApi()
        .then(response => {

        })
        .catch(({ ...error }) => {
          dispatch({ type: GENERATE_TOKEN, payload: error.response.data });
          reject(error)
        });
    };
  })
}

export const LogoutAction = () => {
    return (dispatch) => {
      return dispatch({type: LOGOUT, payload: "LOGOUT"})
    }
  }

