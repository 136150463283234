export const getPercentageChange =(lastMonth, currentMonth, absoluteValue) =>{
    let calc
    let diff = currentMonth - lastMonth;
    
    if (currentMonth > lastMonth ) {
        calc = (diff/currentMonth)*100;
    } else {
        calc = (diff/lastMonth)*100;
    } 
    // let calc = (decreaseValue / oldNumber) * 100;
    if(calc > 0 || calc < 0) {
      if (absoluteValue == 'absTrue') {
        return Math.abs(calc.toFixed(2));
      } else {
        return calc.toFixed(2);
      }
    }
    else return 0
   
  }


 export const getGraphValueArr  = (userCountStash) => {
    let newObj = {...userCountStash};
    if (newObj) {

      let newAr  = []
      let arrObj = {}

      let keys = Object.keys(newObj)
      let value = Object.keys(newObj[keys[0]])

        value.map((obj, index)=>{
          let _obj = {}
          let keys = Object.keys(newObj)  
          keys.map((key, inde)=>{
            let _val = newObj[key][obj]
            _obj[key] = _val
          })
          arrObj[obj] =  _obj
        })
        return arrObj
    }
  } 


  export const getGraphScaleValueArr = (arr) => {
    let newObj = {}
    for(let [key,value] of Object.entries(arr)){
  
        let minmaxArr = Object.values(value)
        let sum = minmaxArr.reduce((previous, current) => current += previous);
        let avg = sum / minmaxArr.length;
        let _obj = {}
        for (let [_key,_value] of Object.entries(value)){
          let newVal = _value/avg
          _obj[_key] =  Math.ceil(newVal)*10
        }
        newObj[key] = _obj
    }
    return newObj
  }