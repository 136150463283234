import {
  GENERATEOTP,
  GETPROVIDER,
  OTPERROR,
  LOGIN,
  LOGIN_ERROR,
  GENERATE_TOKEN, CALLINGCODE
} from "../constants";
const initialState = {
  otpValue: {},
  ProviderData: {},
  loginSuccesData: {},
  loginErrorData: {},
  GenerateTokenData: {},
  accessToken: {},
  callingCode: {}
};
const loginData = (state = initialState, action) => {
  switch (action.type) {
    case GENERATEOTP:
      return {
        ...state,
        otpValue: action?.payload,
      };
    case OTPERROR:
      return {
        ...state,
        OtpError: action.payload,
      };
    case GETPROVIDER:
      return {
        ...state,
        ProviderData: action.payload,
      };
      case CALLINGCODE:
        return {
          ...state,
          callingCode: action.payload,
        };
    case LOGIN_ERROR:
      return {
        ...state,
        loginErrorData: action.payload,
      };

    case LOGIN:
      let accessToken = action?.payload?.data?.data?.accessToken;
      return {
        ...state,
        loginSuccesData: action?.payload?.data?.data,
        accessToken: accessToken,
        loginErrorData: {},
      };

    case GENERATE_TOKEN:
        // console.log("*********************************", action.payload, "$$$$$$$$$$$$", state)
      if (action?.payload?.data?.token.accessToken) {
        let loginData = state.loginSuccesData;
        loginData.accessToken = action.payload.data.token.accessToken;
        loginData.accessTokenExpiry =
          action.payload.data.token.accessTokenExpiry;
        return {
          ...state, // not value lose
          loginSuccesData: loginData,
          accessToken: action.payload,
        };
      } else return state;

    default:
      return state;
  }
};

export default loginData;
