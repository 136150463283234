import React, { useEffect, useRef, useState } from "react";
import User3 from "../../assets/images/user-3.jpg";
import User5 from "../../assets/images/user-5.jpg";
import UserPhoto from "../../assets/images/user-photo.png";
import { Chart } from "chart.js";
import { Select, CaretIcon, ModalCloseButton } from "react-responsive-select";
import "react-responsive-select/dist/react-responsive-select.css";
import { useDispatch, useSelector } from "react-redux";
import {
    getRevenueActionFn,
  getTopReceiverActionFn,
  getTopSendersActioFn,
  paymentVsRequestActionFn,
} from "../../redux/actions/transactionAction";

const BusinessChart = (props) => {
  const dispatch = useDispatch();
  
  const [paymentVsRequestData, setPaymentVsRequestData] = useState([])
  const [revenueGrowthData, setRevenueGrowthData] = useState([])
  const [topSendersData, setTopSendersData] = useState([])  

  useEffect(() => {
    getPaymentvsRequest(6);
    getTopSenders();
    getRevenueGrowth(6);
  }, []);


  //======== get payment vs request ============================
  const getPaymentvsRequest = async (month) => {
    try{
      let paymentdata = {
        account_type: "Business",
        month: month,
      };
      const response  = await dispatch(paymentVsRequestActionFn(paymentdata));
      const paymentVsRequestResponse = response?.payload;
      if (paymentVsRequestResponse){
        if(paymentVsRequestResponse?.status == 200){
          if(paymentVsRequestResponse?.data){
            barChart1(paymentVsRequestResponse?.data)
            setPaymentVsRequestData(paymentVsRequestResponse?.data)
          }
        } else {
          console.error(paymentVsRequestResponse)
        }
      }
    } catch (err){
      console.error(err)
    }
  };

  //======== get revenue growth  ============================
  const getRevenueGrowth = async (month) => {
    try{
      const response  = await dispatch(getRevenueActionFn(month));
      const revenueResponse = response?.payload;
      if (revenueResponse){
        if(revenueResponse?.status == 200){
          if(revenueResponse?.data){
            barChart2(revenueResponse?.data?.result)
            setRevenueGrowthData(revenueResponse?.data?.result)
          }
        } else {
          console.error(revenueResponse)
        }
      }
    } catch (err){
      console.error(err)
    }
  }
  
  //========= get top sender =======================
  const getTopSenders = async () => {
    try{
      const response  = await dispatch(getTopSendersActioFn());
      const topSenderResponse = response?.payload;
      if (topSenderResponse){
        if(topSenderResponse?.status == 200){
          if(topSenderResponse?.data){
            setTopSendersData(topSenderResponse?.data)
          }
        } else {
          console.error(topSenderResponse)
        }
      }
    } catch (err){
      console.error(err)
    }
  };


// Revenue Growth Chart
  const barChart2 = (revenuedata) => {
    var ctx1 = document.getElementById("barChart").getContext("2d");
    var barChart = new Chart(ctx1,{
        type: "bar",
  fillOpacity: 0.8,
  data: {
    
    labels: revenuedata?.result
    ? revenuedata?.result.map((a) => a.dateRange.split(" "))
    : ["jan","feb","mar","apr","may"],
    datasets: [
      {
        label: "revenue",
        backgroundColor: "#38B635",
        borderColor: "#38B635",
        pointBorderColor: "#38B635",
        borderWidth: 2,
        pointRadius: 4,
        pointHoverRadius: 4,
        pointBackgroundColor: "#FFF",
        data: revenuedata?.result
        ? revenuedata?.result.map((a) => a.count)
        : [0,0,0,0,0,0],
      },
    ],
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    bezierCurve: false,
    elements: {
      line: {
        tension: 0,
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            // autoSkip: false,
            // maxRotation: 100,
            // minRotation: 50,
          },
          gridLines: { color: "rgba(0, 0, 0, 0)" },
        },
      ],
      yAxes: [
        {
          ticks: { beginAtZero: true },
          gridLines: { color: "rgba(244, 244, 244, 1)" },
        },
      ],
    },

    tooltips: {
      custom: function (tooltip) {
        if (!tooltip) return;
        // disable displaying the color box;
        tooltip.displayColors = false;
      },
      callbacks: {
        // use label callback to return the desired label
        label: function (tooltipItem, data) {
          return "GH₵" + tooltipItem.yLabel;
        },
        // remove title
        title: function (tooltipItem, data) {
          return;
        },
      },
      backgroundColor: "#FFF",
      borderColor: "rgba(0, 0, 0, 0.09)",
      borderWidth: 1,
      bodyFontColor: "rgba(0, 0, 0, 1)",
      bodyAlign: "center",
      bodyFontSize: 14,
      bodyFontStyle: 500,
    },
    legend: {
      align: "end",
      labels: {
        boxWidth: 12,
        fontColor: "#A4A7B0",
      },
    },
  },
    })

  };



  //  Payment vs Request Chart 
  const barChart1 = (paymentvsreq_data) => {
    var ctx2 = document.getElementById("myChart2").getContext("2d");
    var gradientFill = ctx2.createLinearGradient(0, 250, 0, 130);
    gradientFill.addColorStop(1, "rgba(56, 182, 53, 1)");
    gradientFill.addColorStop(0, "rgba(255, 255, 255, 0)");

    var gradientFill2 = ctx2.createLinearGradient(0, 250, 0, 80);
    gradientFill2.addColorStop(1, "rgba(207, 238, 206, 1)");
    gradientFill2.addColorStop(0, "rgba(255, 255, 255, 0)");
    var myChart2 = new Chart(ctx2, {
      type: "line",
      fillOpacity: 0.8,
      data: {
        labels: paymentvsreq_data.graph
        ? paymentvsreq_data.graph.map((a) => a.month)
        : ["jan","feb","mar","apr","may"],
        datasets: [
          {
            label: "Payments",
            backgroundColor: gradientFill,
            borderColor: "#38B635",
            pointBorderColor: "#38B635",
            borderWidth: 2,
            pointRadius: 4,
            pointHoverRadius: 4,
            pointBackgroundColor: "#FFF",
            data: paymentvsreq_data.graph
            ? paymentvsreq_data.graph.map((a) => a.paymentCount)
            : [0,0,0,0,0,0],
          },
          {
            label: "Requests",
            backgroundColor: gradientFill2,
            borderColor: "#CFEECE",
            pointBorderColor: "#CFEECE",
            borderWidth: 2,
            pointRadius: 4,
            pointHoverRadius: 4,
            pointBackgroundColor: "#FFF",
            data: paymentvsreq_data.graph
            ? paymentvsreq_data.graph.map((a) => a.requestCount)
            : [0,0,0,0,0,0],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        bezierCurve: false,
        elements: {
          line: {
            tension: 0,
          },
        },
        scales: {
          xAxes: [
            {
              gridLines: { color: "rgba(0, 0, 0, 0)" },
            },
          ],
          yAxes: [
            {
              ticks: { beginAtZero: true },
              gridLines: { color: "rgba(244, 244, 244, 1)" },
            },
          ],
        },

        tooltips: {
          custom: function (tooltip) {
            if (!tooltip) return;
            // disable displaying the color box;
            tooltip.displayColors = false;
          },
          callbacks: {
            // use label callback to return the desired label
            label: function (tooltipItem, data) {
              return "GH₵" + tooltipItem.yLabel;
            },
            // remove title
            title: function (tooltipItem, data) {
              return;
            },
          },
          backgroundColor: "#FFF",
          borderColor: "rgba(0, 0, 0, 0.09)",
          borderWidth: 1,
          bodyFontColor: "rgba(0, 0, 0, 1)",
          bodyAlign: "center",
          bodyFontSize: 14,
          bodyFontStyle: 500,
        },
        legend: {
          align: "end",
          labels: {
            boxWidth: 12,
            fontColor: "#A4A7B0",
          },
        },
      },
    });
  };



  return (
    <div className="sec-block alt">
      <div className="row modified">
        <div className="col-xl-5 col-lg-12">
          <div className="block-single alt">
            <div className="block-heading">
              <h2>Payments vs Requests</h2>
              <div className="custom-select-wrap select-month">
              <select
                  className="custom-select"
                  name="carType1"
                  onChange={(e) => getPaymentvsRequest(e.target.value)}
                >
                  <option value="6">6 Months</option>
                  <option value="12">12 Months</option>
                  <option value="18">18 Months</option>
                  <option value="24">24 Months</option>
                </select>
              </div>
            </div>
            <div className="inde-business-chart">
              <canvas id="myChart2" />
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6">
          <div className="block-single">
            <div className="block-heading">
              <h2>Top Senders</h2>
            </div>
            <div className="recent-trans">
              <ul className="list-item">
                {topSendersData?.result?.length > 0 &&
                  topSendersData.result.map((sender, i) => {
                    return (
                      <li key={i}>
                        <span className="list-item-img">
                          <img src={sender.senderAvtar} alt="" />
                        </span>
                        <span className="list-item-txt">
                          <h3>{sender.senderName}</h3>
                          {/* <h4>
                      <span>to business</span>
                    </h4> */}
                        </span>
                        {/* <span className="list-item-details">
                    <h5>
                      -GH₵1,651.31 <span>Aug 08, 2020</span>{" "}
                    </h5>
                  </span> */}
                        <span className="list-item-details">
                          <h5>
                            {sender.countryCode} {sender.senderPhone}
                          </h5>
                        </span>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
        <div className="col-xl-4">
          <div className="block-single alt">
            <div className="block-heading">
              <h2>Revenue Growth</h2>
              <div className="custom-select-wrap select-month">
              <select
                  className="custom-select"
                  name="carType1"
                  onChange={(e) => getRevenueGrowth(e.target.value)}
                >
                  <option value="6">6 Months</option>
                  <option value="12">12 Months</option>
                  <option value="18">18 Months</option>
                  <option value="24">24 Months</option>
                </select>
              </div>
            </div>
            <div className="inde-business-chart">
            <canvas id="barChart" />

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessChart;
