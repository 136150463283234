import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/logo.svg";
import { Link, useHistory, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { LogoutAction } from "../../redux/actions/loginAction";
import { UserSwitch } from "./UserSwitch";
import qr_icon from '../../assets/images/qr-code.svg';

const Header = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();

  const CurrentUser = useSelector((state) => state.UserData.currentuser);
  const [homeRoute, setHomeRoute] = useState('');
  const [currentUserType, setCurrentUserType] = useState('')

  
  useEffect(()=>{
    if (CurrentUser){
      getHomeRoute()
    } else {
      setHomeRoute('/')
    }
  },[CurrentUser])
  
  const getHomeRoute = () =>{
    const userType = CurrentUser?.data
    let route
    if (userType == 'Individual'){
      route = '/user-dashboard'
    } else if (userType == 'Business'){
      route = '/business-dashboard'
    } else if (userType == 'Merchant'){
      route = '/merchant-dashboard'
    }
    setHomeRoute(route)
    setCurrentUserType(userType)
  } 


  const logOut = () => {
    dispatch(LogoutAction());
    // console.log('logout')
    localStorage.removeItem('accessToken')
    history.push('/')
  };


  return (
    <>
      <header className="site-header">
        <div className="row align-items-center">
          <div className="col-xl-2 col-lg-3 col-sm-5 col-7">
            <div className="logo">
              <div className="mobClick">
                <span></span>
                <span></span>
                <span></span>
              </div>
              <Link to={homeRoute}>
                <img src={Logo} alt="" />
              </Link>
            </div>
          </div>
          <div className="col-xl-10 col-lg-9 col-sm-7 col-5">
            <div className="header-right">
              <div className="top-search">
                {currentUserType == 'Business' || currentUserType=='Merchant'? 
                  <Link className="" onClick={()=>{history.push('/qrcode-generate')}} style={{textDecoration:"none", color:"white"}}>
                    <div className="dropdown btnSet">
                      <div
                        className="dropdown-toggle"
                      >
                      <img src={qr_icon} style={{width:'25px'}} />
                      </div>
                    </div>
                    <span className="ml-3">QR Code</span>
                  </Link>
                : <></>}
                {/* <div className="search-wrap clearable">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                  />
                  <i className="fa fa-search" aria-hidden="true"></i>
                  <i className="clearable__clear icon-icon-close2"></i>
                </div> */}
              </div>
              <div className="dropdown btnSet">
                <div
                  className="dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                >
                <i className="fa fa-cog" aria-hidden="true"></i>
              </div>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <Link className="dropdown-item" to={"/user-settings"}>
              Account Settings
            </Link>
            <Link className="dropdown-item" onClick={logOut}>
              Log Out
            </Link>
          </div>
        </div>
             
            <UserSwitch/>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default withRouter(Header);
