import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ArrowDown from "../../assets/images/arrow-down.svg";
import ArrowUp from "../../assets/images/arrow-up.svg";
import BusinessChart from "./business-charts";
import Header from "../common/header";
import { userStatsActionFn } from "../../redux/actions/userDetailsAction";
import BusinessTransactions from "./businessTransactions";
import { getGraphScaleValueArr, getGraphValueArr, getPercentageChange } from "../../redux/utilities";

const CustomerDashboard = (props) => {
    const dispatch = useDispatch();
  
    const userCountStash = useSelector((state) => state.UserData.statistics.result
    );
    const [paymentCount, setPaymentCount] = useState(0);
    const [prevPaymentCount, setPrevPaymentCount] = useState(0);
    const [reqCount, setReqCount] = useState(0);
    const [prevReqCount,setPrevReqCount] = useState(0);
    const [receiveCount, setReceiveCount] = useState(0);
    const [prevReceiveCount,setPrevReceiveCount] = useState(0);
    const [sentAmount, setSentAmount] = useState(0);
    const [prevSentAmount,setPrevSentAmount] = useState(0);
    //======================= Small Graph =====================================
    const [valueArr, setValueArr] = useState({});
    const [graphScaleValue, setGraphScaleValue] = useState({});
    const [paymentCountObject, setPaymentCountObject] = useState({})
    const [requestCountObject, setRequestCountObject] = useState({})
    const [totalReceiveCountObject, setTotalReceiveCountObject] = useState({})
    const [totalSentCountObject, setTotalSentCountObject] = useState({})
  
    useEffect(() => {
      getUserWalletDetail()
    }, []);
  

    const getUserWalletDetail = async () => {
      try {
        const response = await dispatch(userStatsActionFn("Business"));
        const getUserWalletResponse = response?.payload;
        if (getUserWalletResponse){
          if(getUserWalletResponse?.status == 200) {
            const {currentMonth, lastMonth } = getUserWalletResponse.data.result;
            // currentMonth
            setPaymentCount(currentMonth?.paymentCount);
            setReqCount(currentMonth?.requestCount);
            setReceiveCount(currentMonth?.totalReceive);
            setSentAmount(currentMonth?.totalSent);
            // lastMonth
            setPrevPaymentCount(lastMonth?.paymentCount);
            setPrevReqCount(lastMonth?.requestCount);
            setPrevReceiveCount(lastMonth?.totalReceive);
            setPrevSentAmount(lastMonth?.totalSent);
            // Small Graph 
            getSmallGraphValue(getUserWalletResponse.data.result)
  
          } else {
            console.error(getUserWalletResponse)
          }
        }
      } catch (err) {
        console.error(err)
      }
    }
  
  
    const getSmallGraphValue = async (data) => {
      try{      
        const response =  await getGraphValueArr(data);
        setValueArr(response)
        const values = await getGraphScaleValueArr(response)
        setGraphScaleValue(values)
        setPaymentCountObject(values['paymentCount'])
        setRequestCountObject(values['requestCount'])
        setTotalReceiveCountObject(values['totalReceive'])
        setTotalSentCountObject(values['totalSent'])
      } catch (err){
        console.error(err)
      }
    };
  
  return (
    <>
      <Header />

      <section class="dash-wrap">
      <div class="dash-stat">
          <div class="row modified">
            <div class="col-lg-3 col-sm-6">
              <div class="dash-stat-single">
                <h2>{paymentCount}</h2>
                <p>payments this month</p>
                <div class="dash-stat-graph">
                  {getPercentageChange(
                    prevPaymentCount,
                    paymentCount
                  ) >= 0 ? (
                    <div className="stat-percentage">
                      +
                      {getPercentageChange(
                        prevPaymentCount,
                        paymentCount, 'absTrue'
                      )}
                      % <img src={ArrowUp} alt="" />{" "}
                    </div>
                  ) : 
                      (
                    <div className="stat-percentage down">
                      -
                      {getPercentageChange(
                        prevPaymentCount,
                        paymentCount, 'absTrue'
                      )}
                      % <img src={ArrowDown} alt="" />{" "}
                    </div>
                  
                  )}

                  <div class="stat-percentage-graph">
                  {paymentCountObject? 
                    <ul>
                        <li>
                          <span 
                          style={{height: `${paymentCountObject["thirdLastMonth"]>0 ? paymentCountObject["thirdLastMonth"]: '2'}%`, }}
                          ></span>
                        </li>
                        <li>
                          <span 
                          style={{height: `${paymentCountObject["secondLastMonth"]>0? paymentCountObject["secondLastMonth"] : '2'}%`, }}
                            ></span>
                        </li>
                        <li>
                          <span 
                            style={{height: `${paymentCountObject["lastMonth"] >0 ? paymentCountObject["lastMonth"] : '2'}%`, }}
                            ></span>
                        </li>
                        <li>
                          <span className={`${getPercentageChange(prevPaymentCount,paymentCount) >= 0 ? "graph-up" : "graph-down"}`}
                            style={{height: `${paymentCountObject["currentMonth"] >0 ? paymentCountObject["currentMonth"] : '2'}%`, }}
                          ></span>
                        </li>
                    </ul>    
                      :<></>}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="dash-stat-single">
                <h2>{reqCount}</h2>
                <p>requests this month</p>
                <div class="dash-stat-graph">
                {getPercentageChange(
                   prevReqCount,
                    reqCount
                  ) >= 0 ? (
                    <div className="stat-percentage">
                      +
                      {getPercentageChange(
                        prevReqCount,
                        reqCount, 'absTrue'
                      )}
                      % <img src={ArrowUp} alt="" />{" "}
                    </div>
                  ) : 
                  (
                    <div className="stat-percentage down">
                      -
                      {getPercentageChange(
                        prevReqCount,
                        reqCount, 'absTrue'
                      )}
                      % <img src={ArrowDown} alt="" />{" "}
                    </div>
                  ) 
                  }
                
                  <div class="stat-percentage-graph">
                  {requestCountObject? 
                    <ul>
                        <li>
                          <span 
                          style={{height: `${requestCountObject["thirdLastMonth"]>0 ? requestCountObject["thirdLastMonth"]: '2'}%`, }}
                          ></span>
                        </li>
                        <li>
                          <span 
                          style={{height: `${requestCountObject["secondLastMonth"]>0? requestCountObject["secondLastMonth"] : '2'}%`, }}
                            ></span>
                        </li>
                        <li>
                          <span 
                            style={{height: `${requestCountObject["lastMonth"] >0 ? paymentCountObject["lastMonth"] : '2'}%`, }}
                            ></span>
                        </li>
                        <li>
                          <span className={`${getPercentageChange(prevReqCount,reqCount) >= 0 ? "graph-up" : "graph-down"}`}
                            style={{height: `${requestCountObject["currentMonth"] >0 ? requestCountObject["currentMonth"] : '2'}%`, }}
                          ></span>
                        </li>
                    </ul>    
                  :<></>}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="dash-stat-single">
                <h2>GH₵ {receiveCount}</h2>
                <p>received this month</p>
                <div class="dash-stat-graph">
                {getPercentageChange(
                    prevReceiveCount,
                    receiveCount
                  ) >= 0 ? (
                    <div className="stat-percentage">
                      +
                      {getPercentageChange(
                       prevReceiveCount,
                        receiveCount, 'absTrue'
                      )}
                      % <img src={ArrowUp} alt="" />{" "}
                    </div>
                  ):(
                    <div className="stat-percentage down">
                      -
                      {getPercentageChange(
                        prevReceiveCount,
                        receiveCount, 'absTrue'
                      )}
                      % <img src={ArrowDown} alt="" />{" "}
                    </div>
                  )}
                  <div class="stat-percentage-graph">
                  {totalReceiveCountObject? 
                    <ul>
                        <li>
                          <span 
                          style={{height: `${totalReceiveCountObject["thirdLastMonth"]>0 ? totalReceiveCountObject["thirdLastMonth"]: '2'}%`, }}
                          ></span>
                        </li>
                        <li>
                          <span 
                          style={{height: `${totalReceiveCountObject["secondLastMonth"]>0? totalReceiveCountObject["secondLastMonth"] : '2'}%`, }}
                            ></span>
                        </li>
                        <li>
                          <span 
                            style={{height: `${totalReceiveCountObject["lastMonth"] >0 ? totalReceiveCountObject["lastMonth"] : '2'}%`, }}
                            ></span>
                        </li>
                        <li>
                          <span className={`${getPercentageChange(prevReceiveCount,receiveCount) >= 0 ? "graph-up" : "graph-down"}`}
                            style={{height: `${totalReceiveCountObject["currentMonth"] >0 ? totalReceiveCountObject["currentMonth"] : '2'}%`, }}
                          ></span>
                        </li>
                    </ul>    
                  :<></>}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="dash-stat-single">
                <h2>GH₵ {sentAmount}</h2>
                <p>sent this month</p>
                <div class="dash-stat-graph">
                {getPercentageChange(
                    prevSentAmount,
                    sentAmount
                  ) >= 0 ? (
                    <div className="stat-percentage">
                      +
                      {getPercentageChange(
                        prevSentAmount,
                        sentAmount, 'absTrue'
                      )}
                      % <img src={ArrowUp} alt="" />{" "}
                    </div>
                  ) : (
                    <div className="stat-percentage down">
                      -
                      {getPercentageChange(
                        prevSentAmount,
                        sentAmount, 'absTrue'
                      )}
                      % <img src={ArrowDown} alt="" />{" "}
                    </div>
                  )}
                  <div class="stat-percentage-graph">
                  {totalSentCountObject? 
                    <ul>
                        <li>
                          <span 
                          style={{height: `${totalSentCountObject["thirdLastMonth"]>0 ? totalSentCountObject["thirdLastMonth"]: '2'}%`, }}
                          ></span>
                        </li>
                        <li>
                          <span 
                          style={{height: `${totalSentCountObject["secondLastMonth"]>0? totalSentCountObject["secondLastMonth"] : '2'}%`, }}
                            ></span>
                        </li>
                        <li>
                          <span 
                            style={{height: `${totalSentCountObject["lastMonth"] >0 ? totalSentCountObject["lastMonth"] : '2'}%`, }}
                            ></span>
                        </li>
                        <li>
                          <span className={`${getPercentageChange(prevSentAmount,sentAmount) >= 0 ? "graph-up" : "graph-down"}`}
                            style={{height: `${totalSentCountObject["currentMonth"] >0 ? totalSentCountObject["currentMonth"] : '2'}%`, }}
                          ></span>
                        </li>
                    </ul>    
                  :<></>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <BusinessChart />
        <BusinessTransactions />
      </section>
    </>
  );
};

// <div class="modal fade" id="transModal-3" tabindex="-1" aria-hidden="true">
//   <div class="modal-dialog modal-dialog-centered common-modal">
//     <div class="modal-content">
//       <div class="modal-header">
//         <h5 class="modal-title" id="exampleModalLabel">Transaction ID #314745</h5>
//         <button type="button" class="close" data-dismiss="modal" aria-label="Close">
//           <i class="icon-icon-close2"></i>
//         </button>
//       </div>
//       <div class="modal-body">
//         <div class="trans-details">
//           <div class="row modified">
//             <div class="col-sm-9">
//               <div class="trans-details-left">
//                 <span class="transDate">Aug 13, 2020</span>
//                 <span class="transName">GH₵277.00</span>
//                 <span class="transStat">Completed</span>
//                 <div class="row modified">
//                   <div class="col-lg-6 tarnsDetails">
//                     <span class="popTitle">Sender</span>
//                     <div class="sender">
//                       <span class="sender-img"><img src={User3} alt=""/></span>
//                       <span class="sender-txt">Diane Larbi <br/> <a href="#">dihunter228@gmail.com</a></span>
//                     </div>
//                   </div>
//                   <div class="col-lg-6 tarnsDetails">
//                     <span class="popTitle">Receiver</span>
//                     <div class="sender">
//                       <span class="sender-img-comp"><i class="icon-icon-school"></i></span>
//                       <span class="sender-txt">School Fees</span>
//                     </div>
//                   </div>
//                   <div class="col-sm-6 tarnsDetails">
//                     <span class="popTitle">Method</span>
//                     <span class="popDesc">Request</span>
//                   </div>
//                   <div class="col-sm-6 tarnsDetails">
//                     <span class="popTitle">Type</span>
//                     <span class="popDesc">School Fees</span>
//                   </div>
//                   <div class="col-sm-6 tarnsDetails">
//                     <span class="popTitle">Institute</span>
//                     <span class="popDesc">Ghana International School</span>
//                   </div>
//                   <div class="col-sm-6 tarnsDetails">
//                     <span class="popTitle">Location</span>
//                     <span class="popDesc">Ghana</span>
//                   </div>
//                   <div class="col-sm-6 tarnsDetails">
//                     <span class="popTitle">Fee Details</span>
//                     <span class="popDesc">Quarterly fees</span>
//                   </div>
//                   <div class="col-sm-6 tarnsDetails">
//                     <span class="popTitle">Student Number</span>
//                     <span class="popDesc">236722</span>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div class="col-sm-3">
//               <div class="trans-details-right">
//                 <ul class="transLink">
//                   <li><a href="#"><i class="icon-icon-refund"></i> Refund</a></li>
//                   <li><a href="#"><i class="icon-icon-download"></i> Export</a></li>
//                   <li><a href="#"><i class="icon-icon-delete"></i> Delete</a></li>
//                 </ul>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>

export default CustomerDashboard;
