import  {LOADING} from '../constants/index'


let iState = {
  loading: false,
};

const loadingReducer = (state = iState, action) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.status };
    default:
      return state;
  }
};


export default loadingReducer