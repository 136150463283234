import React, { useEffect, useState } from "react";
import "react-intl-tel-input/dist/main.css";
import Logo from "../../assets/images/logo.svg";
import {  useHistory } from "react-router-dom";
import {
  GenerateOtpActionFn,
  GetCallingCodeActionFn,
  GetProviderActionFn,
} from "../../redux/actions/loginAction";
import { useDispatch, useSelector } from "react-redux";

const Login = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loginData = useSelector((state) => state?.loginData);

  const ProviderData = useSelector((state) => state?.loginData?.ProviderData);
  const CallingCode = useSelector((state) => state?.loginData?.callingCode?.data);
  const CurrentUser = useSelector((state) => state?.UserData?.currentuser);


  const [operator, setOperator] = useState("");
  const [contact, setcontact] = useState("");
  const [countryCode, setCountryCode] = useState("+233");
  const [Errormsg, setErrorMsg] = useState("");
  const [isFirst, setIsFirst] = useState(true);
  const [operatrError, setOperatorError] = useState(false);
  const [contactError, setContactError] = useState(false);

  useEffect(() => {
    if (loginData?.loginSuccesData?.accessToken) {
       if(loginData?.loginSuccesData?.accountType === "Individual"){
        window.location = "/user-dashboard";
       }
       else if(loginData?.loginSuccesData?.accountType === "Business"){
           window.location = "/business-dashboard";
       }
       else if(loginData?.loginSuccesData?.accountType === "Merchant"){
           window.location = "/merchant-dashboard"
       }
       else
       {
           return
       }
    }
    dispatch(GetProviderActionFn());
    dispatch(GetCallingCodeActionFn());
  }, []);

  useEffect(() => {
    if (!isFirst) {
      if (loginData && loginData?.otpValue) {
        if (loginData?.otpValue?.data && loginData?.otpValue?.data?.status) {
          history?.push({
            pathname: "/otpconfirm",
            state: {
              num: contact,
              operator: operator,
              countryCode: countryCode
            },
          });
        } else if (loginData?.OtpError) {
          setErrorMsg("Please enter a valid mobile number");
        }
      }
    }
  }, [loginData]);


  
  const sendUser = () => {
    if (operator === "") {
      setOperatorError(true);
    } else if (contact === "") {
      setContactError(true);
    } else if (contact?.length < 9) {
      setContactError(true);
    } else {
      let body = {
        phone: contact,
        countryCode: countryCode,
        serviceProvider: operator,
      
      };
      setIsFirst(false);
      dispatch(GenerateOtpActionFn(body));
    }
  };

  return (
    <>
      <div className="sec-login">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-6">
            <div className="logo-login">
              <img src={Logo} alt="" />
            </div>
            <div className="sec-login-left">
              <h1>
                Welcome to <br /> Pocketi!
              </h1>
              <p>
                Log In to proceed to the dashboard to manage all the <br />{" "}
                information regarding users and transactions
              </p>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
            <div className="sec-login-right">
              <h2>Log In</h2>
              {Errormsg ? <p className="error-text">
                <span>{Errormsg}</span>
                </p> : ""}
              <div className="form-group">
                <label htmlFor="operator">
                  Enter your phone number to proceed to Pocketi account
                </label>

                <select
                  className="form-control"
                  value={operator}
                  onChange={(e) => setOperator(e?.target?.value)}
                >
                  <option value="">Select service provider</option>
                  {ProviderData?.data &&
                    ProviderData?.data?.status &&
                    ProviderData &&
                    ProviderData?.data?.data?.length > 0 &&
                    ProviderData?.data?.data?.map((item, i) => {
                      return (
                        <option value={item?.name} key={item?.id}>
                          {item?.name}
                        </option>
                      );
                    })}
                </select>
                {operatrError && !operator && (
                  <p>
                    <span className="error-text">Please select operator</span>
                  </p>
                )}
              </div>
              <div className="form-group d-flex">
                {/* <IntlTelInput
                  containerClassName="intl-tel-input"
                  inputClassName="form-control"
                  type="tel"
                  fieldId="phone"
                  separateDialCode={true}
                  preferredCountries={["gh"]}
                  onPhoneNumberChange={(
                    status,
                    value,
                    countryData,
                    number,
                    id
                  ) => {
                    setcontact(value);
                    setCountryCode(`+${countryData?.dialCode}`);
                  }}
                /> */}
                <select className="form-control" style={{ width: "30%",borderRight:"none", borderRadius:"8px 0px 0px 8px" }}
                value={countryCode}
                onChange={(e)=>setCountryCode(e?.target?.value)}
                >
                  {CallingCode?.country?.map((country) => {
                    return (
                      <option value={country?.callingCode}>
                        {country?.callingCode}
                        &nbsp; &nbsp; &nbsp;
                        {country?.name}
                      </option>
                    );
                  })}
                </select>
                <input
                  type="text"
                  className="form-control"
                  style={{ width: "70%", borderRadius:"0px 8px 8px 0px" }}
                  value={contact}
                  onChange={(e)=>setcontact(e?.target?.value)}
                ></input>
              </div>
              {contactError && !contact && (
                <span className="error-text">Please enter contact number</span>
              )}
              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-orange"
                  onClick={() => sendUser()}
                >
                  Proceed with OTP code
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
