export const GENERATEOTP = "GENERATEOTP";
export const CALLINGCODE = "CALLINGCODE";
export const OTPERROR = "OTPERROR";
export const GETPROVIDER = "GETPROVIDER";
export const LOGIN = "LOGIN";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const GENERATE_TOKEN = "GENERATE_TOKEN";
export const LOGOUT = "LOGOUT";
export const USERDETAILS = "USERDETAILS"
export const SWITCHACCOUNT = "SWITCHACCOUNT"
export const GETTRANSACTIONS = "GETTRANSACTIONS"
export const GET_EXPORT_TRANSACTIONS = "GET_EXPORT_TRANSACTIONS"
export const GET_PDF_TRANSACTIONS_DETAILS = "GET_PDF_TRANSACTIONS_DETAILS"
export const STATISTICS = "STATISTICS"
export const TRANSACTION = "TRANSACTION"
export const TOPRECEIVERS = "TOPRECEIVERS"
export const PAYMENTVSREQUEST = "PAYMENTVSREQUEST"
export const TRANSACTIONTYPE = "TRANSACTIONTYPE"
export const FILTERTYPE = "FILTERTYPE"
export const TOPSENDERS = "TOPSENDERS"
export const REVENUEGRAPH = "REVENUEGRAPH"
export const CURRENTUSER = "CURRENTUSER"
export const MERCHANTSTATS = "MERCHANTSTATS"
export const BUSINESSUSERDATA = "BUSINESSUSERDATA"
export const LOADING = "LOADING";