import store from "../store/store";
import { generateTokenApi } from "../api/ApiService";
import { LogoutAction } from "../actions/loginAction";
import { useDispatch } from "react-redux";
import { LOGOUT } from "../constants";

export const validateToken = async () => {
   
  const data1 = store.getState();
  // console.log(data1)
  const accessTokenExpiry = data1.loginData?.loginSuccesData?.accessTokenExpiry
  // console.log("token expiry",accessTokenExpiry)
  const currentTime = Math.floor(new Date().getTime() / 1000);
  const checkt = currentTime - 60;
//   if (currentTime > accessTokenExpiry) {
    const response = await generateTokenApi();
    // console.log("generate" , response)
    if (response?.response?.statusText === "Unauthorized") {
       store.dispatch({type : LOGOUT})
      window.location.href = "/"
    }
    return response;
  }
//    else {
//     return null;
//   }
  
// }
