import React, { useState, useEffect } from "react";
import "react-intl-tel-input/dist/main.css";
import { useHistory } from "react-router-dom";
import Logo from "../../assets/images/logo.svg";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { GenerateOtpActionFn, SendOtpActionFn } from "../../redux/actions/loginAction";

const OTP = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const LoginData = useSelector((state) => state?.loginData);

  const [showLoader, setshowLoader] = useState(false);
  const [otpValues, setValue] = useState("");
  const [isLogin, setisLogin] = useState(true);
  const [ApiError, setApiError] = useState("");
  const [counter, setCounter] = useState(60);
  const [reotp,setReotp] = useState(false);
  const [timeover,setTimeover] = useState(true);

  useEffect(() => {
    if (
      props?.location?.state?.operator === "" ||
      props?.location?.state?.operator === null ||
      props?.location?.state?.operator === undefined
    ) {
      window.location = "/";
    }
  
  }, []);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    if(counter === 0){
        setTimeover(false)
    }
  }, [counter]);

  useEffect(() => {
    if (!isLogin) {
      handleNavigation()
    }
  }, [LoginData]);



  const resendOtp = () => {
      setCounter(60)
      let loginData = props?.location?.state
    let body = {
        phone: loginData?.num,
        countryCode: loginData?.countryCode,
        serviceProvider: loginData?.operator,
       
      };
    dispatch(GenerateOtpActionFn(body));
  }


  const handleChange = async(otp) => {
    setValue(otp);
    let body = {
      phone: props?.location?.state?.num,
      otp: otp,
      fcmToken: "qwertyuioasdfghjkl23456789dfgh",
    };
    if (otp?.length > 5) {
      setshowLoader(true);
      setisLogin(false);
      try {
        const response = await dispatch(SendOtpActionFn(body));
        const loginAPIResponse = response?.payload
        if (loginAPIResponse){
          if (loginAPIResponse?.status == 200){
            if (loginAPIResponse?.data?.status == true ){
              const token = loginAPIResponse?.data?.data?.accessToken
              localStorage?.setItem('accessToken', token)
              // handleNavigation()
            }
          }
        }

      } catch (err){
        console?.error(err)
      }
    }
  };


  const handleNavigation = () =>{
    if (LoginData?.loginErrorData?.message) {
        setApiError(LoginData?.loginErrorData?.message);
        setshowLoader(false);
    }  
    if (LoginData?.loginSuccesData?.accessToken) {
        const accountType = LoginData?.loginSuccesData?.accountType
        setApiError("");
        setshowLoader(false);
        if (accountType === "Individual"){
          history?.push("/user-dashboard");
        } else if (accountType === "Business" ){
          history?.push("/business-dashboard");
        } else if (accountType === "Merchant" ){
          history?.push("/merchant-dashboard");
        } 
    } else {
        return null
    }

}

  return (
    <>
      <div className="sec-login">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-6">
            <div className="logo-login">
              <img src={Logo} alt="" />
            </div>
            <div className="sec-login-left">
              <h1>
                Welcome to <br /> Pocketi!
              </h1>
              <p>
                Log In to proceed to the dashboard to manage all the <br />{" "}
                information regarding users and transactions
              </p>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
            <div className="sec-login-right">
              <h2>Verify your account</h2>
              {ApiError && <p className="error-text">{ApiError}</p>}
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">
                  Enter OTP sent to{" "}
                  <span className="green">
                    {props?.location?.state?.operator}{" "}
                    {props?.location?.state?.num}
                  </span>
                </label>
                <div className="digit-group clearfix container">
                  <OtpInput
                    numInputs={6}
                    onChange={handleChange}
                    value={otpValues}
                  />
                </div>
              </div>

              {showLoader ? (
                <div className="form-group">
                  <div className="lds-spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <span>Just a moment?.?.?. Checking</span>
                </div>
              ) : (
                <div className="form-group otpStat">
                  <div className="row alt">
                    <div className="col-6">
                      <p className="otpTime">00:{counter}</p>
                    </div>
                    <div className="col-6">
                      <p>
                        <button onClick={()=>resendOtp()} disabled={timeover}>Resend</button>
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OTP;
