import React from "react";
import { Route, Redirect } from "react-router-dom";
import { Loader } from "../components/loader";

// handle the private routes
function PrivateRoute({ component: Component,authTokens, ...rest }) {

  return (
   
    <>
    <Route
      {...rest}
      render={(props) =>
        authTokens ? (
          <>
          <Component {...props} />
          <Loader/>
          </>
        ) : (
          <Redirect 
            to={{ 
              pathname: "/",
              state: { from: props.location } 
            }} 
          />
        )
      }
    />
    </>
  );
}
export default PrivateRoute;