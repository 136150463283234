import { combineReducers } from "redux";
import loginData from "./loginReducer";
import UserData from "./userReducer";
import Transactions from "./transactionReducer";
import loadingReducer from "./loadingReducer"
import { LOGOUT } from "../constants";

const appReducer  = combineReducers({
    loginData,
    UserData,
    Transactions,
    loadingReducer,
})
const rootReducer = (state, action) => {
  let newState 
  if (action?.type === LOGOUT) {
    localStorage.removeItem('persist:root')
    newState = undefined;
  } else {
    newState = state
  }
    return appReducer(newState, action)
  }

export default rootReducer;