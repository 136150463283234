import React, { useEffect, useState } from "react";
import { Chart } from "chart.js";
import "react-responsive-select/dist/react-responsive-select.css";
import { useDispatch } from "react-redux";
import {
  merchantTransactionStatisticsActionFn,
} from "../../redux/actions/transactionAction";

const MerchantChart = (props) => {
  const dispatch = useDispatch();
  const [merchantTransData, setMerchantTransData] = useState([])


  useEffect(() => {
    getMerchantTransactionStats(6)
  }, []);

  
  //======== get Merchant transacrion stats ============================
  const getMerchantTransactionStats = async (month) => {
    try{
      const response  = await dispatch(merchantTransactionStatisticsActionFn(month));
      const merchantTransactionStats = response?.payload;
      if (merchantTransactionStats){
        if(merchantTransactionStats?.status == 200){
          if(merchantTransactionStats?.data){
            barChart1(merchantTransactionStats?.data)
            setMerchantTransData(merchantTransactionStats?.data)
          }
        } else {
          console.error(merchantTransactionStats)
        }
      }
    } catch (err){
      console.error(err)
    }
  }



  const barChart1 = (merchantdata) => {
    var ctx2 = document.getElementById("myChart2").getContext("2d");
    var gradientFill = ctx2.createLinearGradient(0, 250, 0, 130);
    gradientFill.addColorStop(1, "rgba(56, 182, 53, 1)");
    gradientFill.addColorStop(0, "rgba(255, 255, 255, 0)");

    var gradientFill2 = ctx2.createLinearGradient(0, 250, 0, 80);
    gradientFill2.addColorStop(1, "rgba(207, 238, 206, 1)");
    gradientFill2.addColorStop(0, "rgba(255, 255, 255, 0)");
    var myChart2 = new Chart(ctx2, {
      type: "line",
      fillOpacity: 0.8,
      data: {
        labels: merchantdata?.result
        ? merchantdata?.result.map((a) => a.dateRange)
        : [0,0,0,0,0,0],
        datasets: [
          {
            label: "Transactions",
            backgroundColor: gradientFill,
            borderColor: "#38B635",
            pointBorderColor: "#38B635",
            borderWidth: 2,
            pointRadius: 4,
            pointHoverRadius: 4,
            pointBackgroundColor: "#FFF",
            data: merchantdata?.result
            ? merchantdata?.result.map((a) => a.count)
            : [0,0,0,0,0,0],
          },
         
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        bezierCurve: false,
        elements: {
          line: {
            tension: 0,
          },
        },
        scales: {
          xAxes: [
            {
              gridLines: { color: "rgba(0, 0, 0, 0)" },
            },
          ],
          yAxes: [
            {
              ticks: { beginAtZero: true },
              gridLines: { color: "rgba(244, 244, 244, 1)" },
            },
          ],
        },

        tooltips: {
          custom: function (tooltip) {
            if (!tooltip) return;
            // disable displaying the color box;
            tooltip.displayColors = false;
          },
          callbacks: {
            // use label callback to return the desired label
            label: function (tooltipItem, data) {
              return "GH₵" + tooltipItem.yLabel;
            },
            // remove title
            title: function (tooltipItem, data) {
              return;
            },
          },
          backgroundColor: "#FFF",
          borderColor: "rgba(0, 0, 0, 0.09)",
          borderWidth: 1,
          bodyFontColor: "rgba(0, 0, 0, 1)",
          bodyAlign: "center",
          bodyFontSize: 14,
          bodyFontStyle: 500,
        },
        legend: {
          align: "end",
          labels: {
            boxWidth: 12,
            fontColor: "#A4A7B0",
          },
        },
      },
    });
  };

  
  return (
    <div className="sec-block alt">
      <div className="row modified">
        <div className="col-xl-12 col-lg-12">
          <div className="block-single alt">
            <div className="block-heading">
              <h2>Transactions Statistics</h2>
              <div className="custom-select-wrap select-month">
              <select
                  className="custom-select"
                  name="carType1"
                  onChange={(e) => getMerchantTransactionStats(e.target.value)}
                >
                  <option value="6">6 Months</option>
                  <option value="12">12 Months</option>
                  <option value="18">18 Months</option>
                  <option value="24">24 Months</option>
                </select>
              </div>
            </div>
            <div className="inde-business-chart">
              <canvas id="myChart2" />
            </div>
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default MerchantChart;
